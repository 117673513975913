import React, {Component} from 'react'
import './Events.css'

class Events extends Component {
    


    render() {

        return (
            
            <div className='events'>
                 <div className='events-wrapper'>

                    <div className='events-section-1'>

                        <div className='events-page-heading'>
                            <div>

                            Great News!!!
                            </div>

                        </div>
                            <p>
                            We are now open @ 
                            <br/>
                            Plot no 143-Basement<br/>
                            Sector-27<br/>
                            Gurugram(122002)
                            </p>

                            <p>
                                Looking forward to seeing you with your family and friends.
                            </p>

                            {/* <p>
                                See you soon!!!
                            </p> */}

                        
                        </div>
                    <div className='events-section-2'>
                            <img src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/events.jpg' />
                        </div>
                    </div>
            </div>
        )
    }
}

export default Events;