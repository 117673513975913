import React, {Component} from 'react'
import './CookiePermission.css'

class CookiePermission extends Component {
    render() {
        return(
            <div className='cookie-permission'>
                <span>
                We use cookies to ensure you get the best experience on our website. If you agree to our use of cookies, please continue to use our site. For more information, see our <a href='/privacy'>privacy policy</a>.
                </span>
                <div 
                    className='permission-button'
                    onClick={this.props.onApprove}
                    >
                    OK
                </div>
            </div>
        )
    }
}

export default CookiePermission;