import React, {Component} from 'react'
import './ConsoleGaming.css';
import { HashLink as Link } from 'react-router-hash-link';

class ConsoleGaming extends Component {
    render() {
        return (
            <div className='console-gaming'>

            <div className='gaming-page-wrapper'>

                <div className='console-gaming-section-1'>
                    <div className='gaming-page-heading'>
                        Cross Platform<span style={{color: 'red'}}>Multiplayer</span> 
                    </div>
                    <p>
                    Get your hands on the latest gaming technology and experience
                     a wide range of gaming titles. Every Gaming 
                     Arena is fully equipped with high powered 
                     PlayStation consoles as well as 
                     lightning-fast gaming displays and esports quality 
                     peripherals.
                    </p>
                    <div className='gaming-page-interaction'>

                    <Link to="#show-prices">

                    <button style={{ width: '200px'}}>
                        Get Started!!!
                    </button>
                    </Link>
                    </div>
                    </div>
                <div className='console-gaming-section-2'>
                        <img src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/consolegaming.jpg' />
                    </div>
            </div>

            <div className='gaming-page-wrapper-2'>

                <div className='console-gaming-section-1 second-image'>
                                <img id="show-prices" src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/consolegaming2.jpg' />
                    </div>
                <div className='console-gaming-section-2'>

                <div className='gaming-page-heading' style={{ marginTop: '100px'}}>
                            Let's Play...
                        </div>
                <div className='what-we-offer'>
                            <ul>
                                <li>You don't require any booking(at least for now).
                                     Just contact us or better just visit us. 
                                     We have more than one option of entertainment
                                     </li>
                                <li>Single: 100rs/pp</li>
                                <li>Duration: upto 1 hour</li>
                                <li>Double: 150rs/pp</li>
                                <li>Duration: upto 1 hour</li>
                            </ul>
                        </div>

                    </div>
            </div>


            </div>
        )
    }
}

export default ConsoleGaming;