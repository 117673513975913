import React, {Component} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Nav from './Nav';
import Footer from './Footer';
import Home from './Home';
import Shooting from './Shooting';
import ContactUs from './ContactUs';
import Gallery from './Gallery';
import ConsoleGaming from './ConsoleGaming';
import Snooker from './Snooker'
import Events from './Events'
import PrivacyPolicy from './PrivacyPolicy';

class App extends Component {

  

  render() {
    return (
      <Router>
          <div className='container'>
            <Nav />

            <Routes>
              <Route path='/' exact element={<Home />}/>
              <Route path='/contact' element={<ContactUs />} />
              <Route path='/gallery' element={<Gallery />} />
              <Route path='/home' element={<Home />} />
              <Route path='/shooting' element={<Shooting />} />
              <Route path='/gaming' element={<ConsoleGaming />} />
              <Route path='/snooker' element={<Snooker />} />
              <Route path='/events' element={<Events />} />
              <Route path='/privacy' element={<PrivacyPolicy />} />
            </Routes>

            <Footer />

            {/* <div className='video'>
              <video autoPlay muted loop>
                <source src="video.mp4" type="video/mp4" />
              </video>
            </div> */}

            
          </div>

      </Router>
        
    )
  }
}

export default App;