import { LOG_IN, LOG_OUT } from "../actions/login";

const login = (state={}, action) => {
    switch(action.type) {
        case LOG_IN:
            return {
                ...state,
                login: action.auth_token
            }
        case LOG_OUT:
            let obj = Object.assign({}, state)
            delete obj['login']

            return obj
        default:
            return state
    }
}

export default login