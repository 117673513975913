import React, {Component} from 'react'
import { MdContactPhone, MdContactMail, MdWork } from 'react-icons/md'
import './Footer.css';

class Footer extends Component {
    render() {
        return (
            <div className='footer'>

            
                <div className='footer-text'>

                &#169;gamefame.in
                </div>

                <div className='footer-text'>
                    <MdContactPhone color='white' size={25}/>
                    &nbsp;
                     <a href='tel:09990685643' className='cnumber'>+91 99906 85643</a>
                </div>
                
                <div className='footer-text'>
                    <MdContactMail color='white' size={25}/>
                    &nbsp;

                    <a href='mailto:guru.gamefame@gmail.com' className='cemail'>guru.gamefame@gmail.com</a>
                </div>

            </div>
        )
    }
}

export default Footer;