import React, {Component} from 'react'
import './Home.css';
import { HashLink as Link } from 'react-router-hash-link';


class Home extends Component {


    render() {
        return (
            <div className='home-page'>

                <div className='home-page-wrapper-3'>
                    <div className='console-home-section-2'>
                        <img src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/newopen.jpg' />
                    </div>
                    <div className='console-home-section-1'>

                    <div className='home-page-heading'>
                        Great News!!!
                    </div>
                    <p>
                        After the amazing turn over of enthusiastic shooters in Sector 39 - Gurugram academy. 
                        We have opened another branch of Shooting Academy in Sector 27 - Gurugram. 
                        We hope to see you there, along with your family and friends.
                    </p>

                    <div className='shooting-page-interaction'>
                            {/* <button>
                                Prices
                            </button> */}
                            <Link to="/contact#new-loc">
                                
                            <button style={{ width: '200px'}}>
                                Click for more details!!!
                            </button>
                            </Link>
                        </div>
                    </div>

                </div>

                <div className='home-page-wrapper'>

                <div className='console-home-section-1'>
                    <div className='home-page-heading'>
                        Who we<span style={{color: 'red'}}>ARE</span> 
                    </div>
                    <p>
                    Guru Dronacharya Shooting Academy was first opened in Bhiwani in year 2012. Now we have three more branches in one in Charkhi Dadri and two in Gurugram (Section 39 and newly opened branch in Sector 27). Our range has given more than ten shooters to the Indian team. It has a strength of 200 plus shooters in which 60% of shooters are national players. Our mission  is to build world class shooting champions  and provide friendly environment for all ages to excel in shooting sport. A wide variety of options are available so whether you are looking for personal one-to-one tuition, group coaching or large corporate events, we will be happy to hear from you and build a package to suit your needs.

Guru Dronacharya Shooting is the only academy in Gurgaon which has an ISSF certified coach.
                    </p>
                    <div className='home-page-interaction'>

                    <Link to="#show-prices">

                    {/* <button style={{ width: '200px'}}>
                        Get Started!!!
                    </button> */}
                    </Link>
                    </div>
                    </div>
                <div className='console-home-section-2'>
                                <img src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/home.jpg' />
                    </div>
            </div>

            <div className='home-page-wrapper-2'>

                <div className='home-page-heading' style={{marginTop: '100px'}}>
                    What we offer

                </div>

                <div className='offerings'>
                    <div className='homecard'>
                        <div className='card'>
                            <img src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/shootingfront.jpg'></img>
                            <div className='card-heading'>Shooting</div>
                            <div className='card-text'>
                                Whether you want it casual or professional we have got options for both.
                            </div>
                            <Link to="/shooting">

                                <button style={{ width: '200px'}}>
                                    Get Started!!!
                                </button>
                            </Link>
                        </div>
                        <div className='card'>
                            <img src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/consolefront.jpg'></img>
                            <div className='card-heading'>Gaming</div>
                            <div className='card-text'>
                                We have got the latest console with amazing Games.
                                Get in touch with us to enquire more about the games available
                            </div>
                            <Link to="/gaming">

                                <button style={{ width: '200px'}}>
                                    Get Started!!!
                                </button>
                            </Link>
                        </div>
                        <div className='card'>
                            <img src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/snookerfront.jpg'></img>
                            <div className='card-heading'>Snooker</div>
                            <div className='card-text'>
                                Love the game of snooker, don't worry we have got you covered.
                                Bring friends or family to enjoy a game (or two or more :-D ) of snooker.
                            </div>
                            <Link to="/snooker">

                                <button style={{ width: '200px'}}>
                                    Get Started!!!
                                </button>
                            </Link>
                        </div>
                    </div>

                </div>
                    
                
            </div>

            </div>
        )
    }
}

export default Home;