import React, {Component} from 'react'
import './PrivacyPolicy.css'

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className='privacy'>

<p style={{fontWeight: 'bold'}}>
Privacy Policy
</p>

<p style={{fontWeight: 'bold'}}>
A GameFame Privacy Policy
</p>

<p style={{fontWeight: 'bold'}}>
Last updated: October 10, 2022
</p>

<p>

The GameFame company is committed to protecting your privacy. This Privacy Policy sets out how GameFame collects, 
stores, and uses your personal data or personal information (your “data” or “information”) in connection with 
your use of the services we offer (collectively, the “Services“) and your use of our websites at gamefame.in and 
all subdomains. Different laws refer to “personal data” 
or “personal information,” so the terms “data” and “information” are used interchangeably in this Privacy Policy.
</p>

<p>
Please read this Privacy Policy carefully to understand our practices and your rights in relation to your information. 
This Privacy Policy applies only to information that we collect. If you do not agree with this Privacy Policy in general 
or any part of it, you should not use our Services or access our Website.
</p>

<div>
<b>
1. INFORMATION WE COLLECT
</b>

<p>
We collect and process certain information about you when you use our Services because that collection 
and processing is within our legitimate interests, such as to provide, improve, maintain, and enhance 
our Services and technology, to provide you with information about our Services, and to ensure the 
security of the Services and our Website.
</p>

<p>

We collect and process the following personal information that we receive in connection with your use of the Services 
and/or Website:

</p>
<ul className='privacy-ul'>

<li className='privacy-li'>
<b>Identifiers:</b> This may include information such as your first and last name, email address, country, IP address, and browser and 
operating system configuration.
</li>
<li className='privacy-li'>
<b>Technical Usage/Activity Information:</b> This includes technical information automatically collected from your computer, 
mobile device, or another access device-related to how you use the Services or Website, such as cookies data and device 
information (including but not limited to, identifier, name, and type of operating system). This also includes standard 
web information like your browser type, browser data, usage data, and the pages you access and actions you take on our Website.
</li>
<li className='privacy-li'>
<b>Provided Information:</b> Any other information you affirmatively provide to us when signing up for or using the Services, 
including data you input and any marketing preferences you provide us with.
</li>
</ul>
</div>


<div>
<b>
2. HOW WE USE YOUR INFORMATION
</b>
<p>

We may use your information in pursuing our legitimate interest in operating the services. Specifically, we use your information as follows:
</p>

<ul className='privacy-ul'>


<li className='privacy-li'>

<b>Your Account and Support:</b> To communicate with you about your account and provide customer support to you when you create an account, 
post information on our Website, or submit questions, queries or feedback via our Website, survey tool, email, or social media.
</li>

<li className='privacy-li'>

<b>Advertising:</b> To advertise our Services and business features to you and provide you with news and updates related to GameFame and our Services, 
including through direct marketing and interest-based advertising.
</li>
<li className='privacy-li'>

<b>Customer Support and Communication:</b> To provide our Services, communicate with you, and contact you about our Services when you 
inquire about our product via our Website, App, email (if you send an email to us), or social media (if you contact us via social media)
 and to communicate with you through our newsletters and emails where you have provided your consent.
</li>
<li className='privacy-li'>

<b>Personalization:</b> We use the Technical Usage Information we collect as follows: to personalize our Website to ensure content from 
the Website is presented in the most effective manner for you and your device; to monitor and analyze trends, usage, 
and activity in connection with our Website and Services; to administer and improve the Website; for internal operations 
such as troubleshooting, data analysis, testing, research, statistical, and survey analysis; to keep the Website safe and secure.
</li>
<li className='privacy-li'>

<b>Ensuring Compliance:</b> We may use your information to ensure that you are using our services as permitted and to avoid or mitigate a
 violation of the applicable terms or agreement governing your use of the Services.
</li>
<li className='privacy-li'>

<b>Consent:</b> For any other purposes to which you have consented.
</li>
</ul>

</div>


<div>
<b>

3. COOKIES
</b>

<p>

We use cookies, pixel tags, web beacons, and similar files or technologies (“Cookies”) to collect and store the information we 
automatically collect about your computer, mobile device, and use of the Website. Cookies are files containing small amounts of 
information that are downloaded to your computer or mobile device when you visit a certain webpage. Cookies are then sent back 
to the originating webpage on each subsequent visit, or to another webpage that recognizes that cookie. Cookies are used in order 
to make our Website work, or to work more efficiently, and to improve your experience of our Website.
</p>
<p>

In addition to cookies that are strictly necessary to operate the Website, we use the following cookies:
</p>

<ul className='privacy-ul'>

<li className='privacy-li'>

<b>Functional Cookies:</b> These cookies allow us to enhance your experience on our Website. These cookies are set by us.
</li>
<li className='privacy-li'>

<b>Performance or Analytics Cookies:</b> These cookies enable us to provide a better user experience based on how you use our Website.
One of our third-party analytics tools is Google Analytics provided by Google, Inc. Google Analytics uses cookies, 
small text files containing a string of alphanumeric characters and which are stored on your computer to provide us with 
statistics on the use of our Website and Services. The reports that we receive from Google disclose aggregate usage trends. 
For further information on how Google Analytics collects and processes information about you and information on how to opt out 
from Google’s collection of information from you, please click <a href='https://support.google.com/analytics/answer/181881?hl=en'>here</a>.
</li>
</ul>

<p>

You can opt-out of Google Analytics without affecting how you visit our Website. 
For more information on opting out of being tracked by Google Analytics across all websites you use, see <a href='https://support.google.com/analytics/answer/181881?hl=en'>here</a>.
</p>
<ul className='privacy-ul'>

<li className='privacy-li'>

<b>Targeting or Advertising Cookies:</b> These cookies tell us and the third parties setting them about your browsing habits, 
so you can be provided with advertising that is more relevant to you. These cookies are set by us or third parties 
(such as Google AdWords, Bing Ads, DoubleClick, and LinkedIn).
</li>
<li className='privacy-li'>

<b>Social Media Cookies:</b> These cookies allow you to share what you have been doing on the Website on social media.
</li>
<li className='privacy-li'>

<b>Disabling Cookies:</b> If you do not accept the use of these cookies, please disable them by changing your browser settings 
so that cookies from the Website cannot be placed on your computer or mobile device. 
These browser settings will typically be found in the “options” or “preferences” menu of your browser; 
otherwise, you should use the “Help” option in your browser for more details. If you only want to limit third-party advertising cookies, 
you can turn such cookies off by visiting the following links:</li>
<li className='privacy-li'>Your Online Choices (http://www.youronlinechoices.com/)</li>
<li className='privacy-li'>Network Advertising Initiative (http://www.networkadvertising.org/)</li>
<li className='privacy-li'>Digital Advertising Alliance (http://www.aboutads.info/consumers)</li>

</ul>
</div>

<div>
<b>

4. INFORMATION WE SHARE WITH THIRD PARTIES
</b>

<p>

We do not sell your information to third parties, and we do not pass on your information to any other websites. 
We may share your information with the third parties listed in this section.
</p>

<p>
<b>

Cloud Providers
</b>
</p>

<ul>

<li className='privacy-li'>

<b>Cloud Storage Providers:</b>  We may share your information with Cloud storage providers, including AWS located in the Europe, to store the 
information you provide, provide our Services to you, provide disaster recovery services, and to perform any contract between you and us.
</li>
</ul>

<p>
<b>

Third-Party Services and Sub-processors
</b>
</p>
<p>

We also utilize other companies and individuals to perform certain functions on our behalf. We will disclose your information 
to these third parties only to the extent necessary to perform their functions. We refer to third parties who process your personal 
information as “sub-processors.” 
</p>

<p>

The categories of third-party services and sub-processors we currently use are:
</p>
<ul className='privacy-ul'>

<li className='privacy-li'>

<b>Payment Processors:</b> Payment processors to process your payment information when you purchase our paid services.
</li>
<li className='privacy-li'>

<b>Analytics:</b> Tools that perform analytics to help us understand your usage of our Services. We provide these third parties with a 
limited amount of your information (such as your email address and signup date) when you visit our Website or use our Services so 
that we can improve our service to you based on your usage. We may also use some of them as a medium for communication, either through 
email or messages within our Services.
</li>
<li className='privacy-li'>

<b>Marketing/Advertising:</b> Marketing and advertising services that help us use information collected about you to market our Services to you.
</li>
<li className='privacy-li'>

<b>Authentication:</b>  Authentication services that authenticate your identity and grant you access to our Services.
</li>
<li className='privacy-li'>

<b>Fraud and Abuse Tracking:</b> Services to track any actual or attempted fraud, abuse, or violation of our applicable terms or agreement in 
connection with the use of our Services.
</li>
</ul>
<p>
<b>

Other Disclosures
</b>
</p>
<p>

Additionally, there are other situations where we may share your personal information with others, such as:
</p>
<ul className='privacy-ul'>

<li className='privacy-li'>

<b>Obligations and Protection:</b> If we have a legal obligation to share your information, if we have to enforce or apply our terms of use or 
other agreements, to detect, prevent, or otherwise address security, fraud, or technical issues, or to protect the rights, property, or 
safety of us, our users, a third party, or the public as required or permitted by law (exchanging information with other companies 
and organizations for the purposes of fraud protection and credit risk reduction).
</li>
<li className='privacy-li'>

<b>Business Transfers:</b> In the event that we sell or merge our business or sell all or substantially all of our assets (or are preparing to 
take these actions), we may disclose your information to the prospective merger entity or buyer, and the information held by us 
about our users will be one of the transferred assets. In these cases, we will take reasonable steps to ensure your information remains 
subject to protections at least as protective as the terms of this Privacy Policy.
</li>
<li className='privacy-li'>

<b>With Your Consent:</b> In other circumstances where we may wish to share your information with third parties, we will send you a notice about 
the intended sharing, and you will have an opportunity to choose not to have your information shared. In addition, if you voluntarily 
upload any information to public areas of the Website you will be sharing that information publicly with other users having access to those areas.
</li>
</ul>

</div>

<div>
<p>
<b>

5. KEEPING YOUR INFORMATION SECURE
</b>
</p>
<p>

In an effort to keep your information secure, when we receive your personal information, we take certain Technical and Organizational Measures 
and Platform Security measures.
</p>
<ul className='privacy-ul'>

<li className='privacy-li'>

<b>Payment Processing:</b> In processing your payments through our payment processors, we do not retain any personally identifiable information 
or any financial information such as credit card or account numbers. Instead, all of that information is provided directly to our third-party 
payment processor.<br/>
Although we have these measures in place, please note that transmitting information over the internet is generally not completely secure, 
and we cannot always guarantee the security of your data.
</li>
</ul>

</div>

<div>

<p>
<b>

6. YOUR RIGHTS IN YOUR INFORMATION
</b>
</p>

<p>

You may have certain legal rights in your personal information. If you reside in the European Economic Area, these rights are governed by the 
European Union’s General Data Protection Regulation (“GDPR”); if you are a resident of California, these rights are governed by the 
California Consumer Privacy Act (“CCPA”); if you reside or are located in a different jurisdiction, these rights may be governed by the 
applicable data protection laws of your jurisdiction. We are committed to complying with these data and privacy laws.
</p>

<p>
<b>

GDPR
</b>
</p>

<p>

Under GDPR, subject to certain conditions and exceptions, your rights in your personal information include the following:
</p>
<p>
<ul className='privacy-ul'>
<li className='privacy-li'>

<b>The right to be informed</b> about whether your personal information is being processed and to request access to and certain information 
about your information we process.
</li>
<li className='privacy-li'>
<b>The right to request that we correct or update</b> any information you believe is inaccurate and to request that we complete any information 
you believe is incomplete.

</li>
<li className='privacy-li'>
<b>The right to request that we erase</b> your personal information in certain situations, including:where it is no longer necessary for us to 
hold your personal information for the purpose originally collected or processed;<br/>
where you have provided your personal information to us with your consent and you wish to withdraw your consent, and there is no other 
legal ground under which we can process your personal data;<br/>
where we are processing your personal information on the basis of legitimate interests and you object to such processing and we cannot 
demonstrate an overriding legitimate ground for the processing; or<br/>
where you believe the personal information we hold about you is being unlawfully processed by us.

</li>
<li className='privacy-li'>
<b>The right to request that we restrict processing</b> of your personal information in certain situations, including:where you believe the 
personal information we hold about you is inaccurate and while we verify accuracy;<br/>
where we want to erase your personal information due to unlawful processing but you want us to continue to store it;<br/>
where we no longer need your personal information for the purposes of our processing but you require us to retain the data for the 
establishment, exercise, or defense of legal claims; or<br/>
where you have objected to us processing your personal information based on our legitimate interests and we are considering your objection.

</li>
<li className='privacy-li'>
<b>The right to request that we transfer personal information</b> collected to another organization or directly to you.

</li>
<li className='privacy-li'>
<b>The right to object to/stop our processing</b> of your personal information based on our legitimate interests. In that case, we will no longer 
process your personal information unless we can demonstrate an overriding legitimate ground. At any time you have the right to object to 
our processing of information about you in order to send you marketing, including where we build profiles for such purposes, and we will 
stop processing the data for that purpose.

</li>
<li className='privacy-li'>

<b>The right to not be subject to automated decision making</b>, including where we build profiles for such purposes, where the decision would have 
a legal effect on you or produce a similarly significant effect.
</li>
</ul>
<p>

Please note that these rights above are limited under GDPR, for example, where fulfilling your request would adversely affect other individuals, 
our trade secrets or intellectual property, where there are overriding public interest reasons, or where we are required by law to retain 
your personal information. In addition, if you consider that our processing of personal data relating to you violates your rights under GDPR, 
you have the right to complain to your applicable data protection supervisory authority.
</p>
<p>

Note About Automated Decision-Making. In accordance with GDPR, ACG may use automated decision making where it is necessary for entering into or 
performing a contract between you and ACG, or otherwise with your consent. ACG uses third-party service providers who employ automated 
decision-making for the purpose of detecting and preventing fraud and abuse of our Services and Website and violations of the agreement 
governing your use of the Services. By creating an account or using the Services, you consent to automated decision making for this purpose. 
We may update the automated techniques and tools we use over time.
</p>
</p>

<p>
<b>

California Residents
</b>
</p>

<p>

If you are a California resident, the CCPA provides you with the following rights:
</p>

<p>

<ul className='privacy-ul'>
<li className='privacy-li'>

<b>The right to know</b> the specific pieces and/or categories of personal information that we have collected about you over the past 12 months, 
the categories of sources for that personal information, the business or commercial purposes for collecting the information, the categories 
of personal information that we have disclosed, and the categories of third parties with which the information was shared. This information is 
listed in the above sections of this Privacy Policy.
</li>
<li className='privacy-li'>

<b>The right to request that we delete</b> the personal information we have collected from you (and direct our third-party service providers to do the same).
 This right is subject to several exceptions where the information is necessary for us or a third party to do any of the following:complete the 
 transaction for which we collected your information or perform our contracts with you;<br/>
provide you with a good or service requested by or reasonably anticipated based on your relationship with us;<br/>
detect security incidents and protect and prosecute against malicious, deceptive, fraudulent, or illegal activity;<br/>
debug to identify and repair errors that impair our Website or Services;<br/>
exercise free speech or ensure others can exercise free speech or other legal rights;<br/>
comply with the California Electronic Communications Privacy Act pursuant to Chapter 3.6 of Title 12 of Part 2 of the California Penal Code;<br/>
engage in public or peer-reviewed scientific, historical, or statistical research in the public interests that adheres to all other applicable 
ethics and privacy laws;<br/>
making internal uses that are reasonably aligned with your expectations based on your relationship with us;<br/>
comply with a legal obligation; or<br/>
make internal lawful use of your information in a manner compatible with the context of providing the information.
</li>
<li className='privacy-li'>

<b>The right to opt-out of the sale of your information.</b>
</li>
<li className='privacy-li'>

We do not sell your personal information or disclose your information to third parties for their direct marketing purposes.
</li>
<li className='privacy-li'>

If in the future we intend to sell or disclose your information for third-party marketing, we will provide you notice; and you will have the 
right to opt-out of the sale of your personal information via a “Do Not Sell My Personal Information” or similar link and you will have the right 
to request certain information about our disclosure of personal information to third parties for their own direct marketing purposes during 
the 12 months preceding your request. This request will be free and may be made once per year.
</li>
<li className='privacy-li'>

<b>The right to not be discriminated against</b> for exercising any of your rights under the CCPA.
</li>
</ul>
</p>


<p>
<b>

Exercising Your Rights
</b>
</p>

<p>

We intend to respond to a request for access or deletion within 1 month of receiving a request under GDPR, within 45 days of receiving a 
request under the California Consumer Privacy Act, or within the time period permitted under any other applicable law. 
If we need more time, we will inform you of the reason and the extension period.
</p>
<p>

Note that we may require that you verify your identity in order for us to process your request. If we are unable to verify your identity, 
we may deny your request and we will let you know if that is the case.
</p>
<p>

Additionally, deleting or restricting the processing of your personal information may impact features of our Services that require that 
information to function. As a result, if you delete or restrict processing of your personal information, you may no longer be able to use 
all or part of the Services.
</p>

<p>

<p>
    <b>

If you are not covered by GDPR or CCPA:
    </b>
    </p>    

<b>Your rights  </b>
                    <p>
                        You have the following rights in relation to your Data:  
                        <ul className='privacy-ul'>
                            <li className='privacy-li'>
                                <span><b>Right to access</b> </span> <b>-  the right to request</b> (i) copies of the information we hold about 
                                you at any time, or (ii) that we modify, update or delete such information. If we provide you 
                                with access to the information we hold about you, we will not charge you for this, unless your 
                                request is "manifestly unfounded or excessive." Where we are legally permitted to do so, we may 
                                refuse your request. If we refuse your request, we will tell you the reasons why. 
                            </li>
                            <li className='privacy-li'>
                                <span><b>Right to correct</b></span> - the right to have your Data rectified if it is inaccurate or incomplete
                            </li>
                            <li className='privacy-li'>
                                <span><b>Right to erase</b></span> - the right to request that we delete or remove your Data from our systems.
                            </li>
                            <li className='privacy-li'>
                                <span><b>Right to restrict our use of your Data</b></span> - the right to "block" us from using your Data or 
                                limit the way in which we can use it. 
                            </li>
                            <li className='privacy-li'>
                                <span><b>Right to data portability</b></span> - the right to request that we move, copy or transfer your Data.
                            </li>
                            <li className='privacy-li'>
                                <span><b>Right to object</b></span> - the right to object to our use of your Data including where we use it for 
                                our legitimate interests.  
                            </li>
                        </ul>
                    </p>
</p>
</div>

<div>
<p>
<b>

7. CHILDREN
</b>
</p>
<p>

We do not knowingly collect personal information from children under age 16 for any purpose. If we learn that we have collected personal 
information of a child under age 16, we will attempt to delete that personal information as soon as possible and take any other measures 
required under applicable law.
</p>
</div>

<div>
<p>
<b>

8. CHANGES TO PRIVACY POLICY
</b>
</p>
<p>

We may make changes to this Privacy Policy at any time. The “Last Updated” date at the top of this page refers to the last date that this 
Privacy Policy was updated. All changes to this Privacy Policy are effective immediately upon notice, which notice we may give by any means, 
including, but not limited to, by posting a revised version of this Privacy Policy or other notice on the Website. 
Please check back frequently for any updates or changes.
</p>
</div>

<div>
<p>
<b>

9. CONTACTING US
</b>
</p>
<p>

To make enquiries, exercise any of your rights set out above, or withdraw your consent to the processing 
                        of your Data (where consent is our legal basis for processing your Data), please contact us via this e-mail 
                        address: <a href='mailto:guru.gamefame@gmail.com'>guru.gamefame@gmail.com</a>
</p>
<p>
    <b>Address:</b> 551-p, Sec-39, 
                                near Bakhtawar Singh Chowk, 
                                Gurugram, 
                                Haryana, 
                                122001
</p>

</div>

            </div>
        )
    }
}

export default PrivacyPolicy;