import React, {Component} from 'react'
import './Snooker.css';
import { HashLink as Link } from 'react-router-hash-link';

class Snooker extends Component {
    render() {
        return (
            <div className='snooker'>

            <div className='snooker-wrapper'>

                <div className='snooker-section-1'>

                    <div className='snooker-page-heading'>
                        <div>

                        For<span className='fstyle'> POOL</span> 
                        </div>

                        <div style={{marginTop: '-20px'}}>
                        Lovers
                        </div>

                    </div>
                        <p>
                        Pool- If you're looking for a game of pool, 
                        then GameFame is the place for you! 
                        You can't beat a game of pool with your family and 
                        friends! Luckily, we got it covered for you
                        </p>

                    <div className='snooker-page-interaction'>
                        <Link to="#show-prices-1">

                        <button style={{ width: '200px'}}>
                            Get Started!!!
                        </button>
                        </Link>
                        {/* <button style={{ width: '100px'}}>
                            Book Now
                        </button> */}
                    </div>
                    </div>
                <div className='snooker-section-2'>
                                <img src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/snooker.jpg' />
                    </div>
            </div>

            <div className='snooker-wrapper-2'>

                <div className='snooker-section-1 second-image-1'>
                                <img id="show-prices-1" src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/snooker2.jpg' />
                    </div>
                <div className='snooker-section-2'>
                <div className='snooker-page-heading' >
                            Let's Roll...
                        </div>
                <div className='what-we-offer'>
                            <ul>
                                <li>You don't require any booking(at least for now).
                                     Just contact us or better just visit us. 
                                     We have more than one option of entertainment
                                     </li>
                                <li>Price: 50rs/pp</li>
                                <li>Duration: upto 1 hour</li>
                            </ul>
                        </div>
                    </div>
            </div>


            </div>
        )
    }
}

export default Snooker;