import React, {Component} from 'react'
import './Shooting.css';
import { HashLink as Link } from 'react-router-hash-link';

class Shooting extends Component {


    render() {
        return (
            <div className='shooting-page'>

                <div className='shooting-page-wrapper'>
                    <div className='shooting-page-section-1'>

                        <div className='shooting-page-heading'>
                            Shooting&nbsp;<span className='fstyle'> Range</span>
                        </div>

                        <div className='what-we-offer'>
                            <ul>
                                <li>Professional Coaching</li>
                                <li>Recreation Activity</li>
                                <li>Group Bookings</li>
                                <li>If you have any issues with getting your fun 
                                    started, then don't hesitate to speak to a 
                                    member of our friendly staff, and they'll 
                                    be happy to assist you.</li>
                            </ul>
                        </div>

                        <div className='shooting-page-interaction'>
                            {/* <button>
                                Prices
                            </button> */}
                            <Link to="#show-prices">
                                
                            <button style={{ width: '200px'}}>
                                Get Started!!!
                            </button>
                            </Link>
                        </div>

                    </div>
                    <div className='shooting-page-section-2'>
                        <img src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/frontpage.jpeg' />

                    </div>
                </div>

                <div className='shooting-page-wrapper-2'>
                    <div className='shooting-page-section-2-gun' >
                    
                    <img id="show-prices" src='https://gamefamewebassests.s3.eu-west-2.amazonaws.com/shootinggun.jpg' className='gunstyle'/>


                    </div>
                    <div className='shooting-page-section-2'>

                    <div className='shooting-page-heading'>
                            Recreation
                        </div>

                        <div className='what-we-offer'>
                            <ul>
                                <li>Price: 550rs/pp + GST</li>
                                <li>Shoots: 40 rounds</li>
                                <li>Duration: upto 1 hour</li>
                            </ul>
                        </div>

                        <div className='shooting-page-heading' style={{ marginTop: '0px'}}>
                            Professional
                        </div>

                        <div className='what-we-offer' >
                            <ul>
                                <li>Admission Fee: 1700rs/pp + GST</li>
                                <li>Monthly Fee: 4200rs/pp + GST</li>
                                <li>Quaterly Fee: 3700rs/pp/pm + GST</li>
                                <li>Don't have bullet or gun. Don't worry we have got you covered</li>
                            </ul>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

export default Shooting;