

export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'

export const login = auth_token => {
    return {
        type: LOG_IN,
        auth_token
    }
}

export const logout = () => {
    return {
        type: LOG_OUT
    }
}