import React, {Component} from 'react'
import './Gallery.css'
import SimpleImageSlider from "react-simple-image-slider";
import 'react-slideshow-image/dist/styles.css';

class Gallery extends Component {
    
    state = {
        // showSlider: true,
        startIndex: 0,
        w: 896,
        h: 502
    }

    
    images = ['https://gamefamewebassests.s3.eu-west-2.amazonaws.com/img1.jpg',
     'https://gamefamewebassests.s3.eu-west-2.amazonaws.com/img2.jpg',
      'https://gamefamewebassests.s3.eu-west-2.amazonaws.com/img3.jpg', 
      'https://gamefamewebassests.s3.eu-west-2.amazonaws.com/img4.jpg', 
      'https://gamefamewebassests.s3.eu-west-2.amazonaws.com/img5.jpg', 
      'https://gamefamewebassests.s3.eu-west-2.amazonaws.com/img6.jpg', 
      'https://gamefamewebassests.s3.eu-west-2.amazonaws.com/img7.jpg']

    imagesSlider = [
        { url: this.images[0] },
        { url: this.images[1] },
        { url: this.images[2] },
        { url: this.images[3] },
        { url: this.images[4] },
        { url: this.images[5] },
        { url: this.images[6] },
        ];

    componentDidMount = () => {
        if (window.innerWidth < 950) {
            this.setState({w: 370, h: 208})
        }

    }
    

    wheel = (e) => {

        var e = window.event || e;
	    var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));

        e.target.style.width = Math.max(110, Math.min(800, e.target.width + (30 * delta))) + "px";
        e.target.style.height = Math.max(140, Math.min(800, e.target.height + (30 * delta))) + "px";


    } 

    changeImage = (index) => {
        this.setState({startIndex: index})
    }



    render() {

        return (
            
            <div className='images-container'>
                {/* {
                    !this.state.showSlider && (

                        
                        this.images.map((image, index) => 
                            <img src={image} key={index} onWheel = {(e) => this.wheel(e)} />
                        )
                        
                    )
                } */}

                <div className='slider'>
                    {/* {
                        // this.state.showSlider && ( */}

                            <SimpleImageSlider
                                width={this.state.w}
                                height={this.state.h}
                                images={this.imagesSlider}
                                startIndex={this.state.startIndex}
                                showBullets={true}
                                showNavs={true}
                            /> 
                    
                            
                        {/* )
                    } */}



                    <div className='image-thumbs'>
                    {
                        // this.state.showSlider && (

                        this.images.map((image, index) => 
                        <img  
                            src={image}       
                            key={index} 
                            onClick={() => this.changeImage(index)}
                            />
                        )
                        // )
                    }

                    </div>

                </div>  
            </div>
        )
    }
}

export default Gallery;